export const datter = {
  convert: (date) => {
    let currentDateObj = new Date(date);
    let numberOfMlSeconds = currentDateObj.getTime();
    let remMlSeconds = 180 * 60 * 1000;
    let dateOb =  new Date(numberOfMlSeconds - remMlSeconds);
    return dateOb.toLocaleDateString('pt-BR') + ' ' + dateOb.toLocaleTimeString('pt-BR');
   },
   rawDate: (date) => {
    let currentDateObj = new Date(date);
    let numberOfMlSeconds = currentDateObj.getTime();
    let remMlSeconds = 180 * 60 * 1000;
    return new Date(numberOfMlSeconds - remMlSeconds);
   }
}