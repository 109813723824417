import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { setContext } from '@apollo/client/link/context'
import Dialog from '@/components/bootstrap-vue-dialog'
import './global-components'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import VueApollo from 'vue-apollo'
import underscore from 'vue-underscore'
import { datter } from '@/libs/dateTool'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client/core'

import App from './App.vue'
import store from './store'
import router from './router'

import useApp from './composables/use-app'
// import VueAxios from 'vue-axios'

Vue.prototype.$datter = datter

Vue.use(underscore)

Vue.use(VueApollo)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Dialog)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

/**
 * -------------------------------------
 *  Apollo Client factory
 * -------------------------------------
 */
const createApolloClient = apiUri => {
  const httpLink = createHttpLink({
    uri: apiUri
  })

  /**
   * -------------------------------------
   *  This makes reactivity work
   * -------------------------------------
   */
  const authLink = setContext((_, { headers }) => {
    /**
     * -------------------------------------
     *  User Token
     * -------------------------------------
     */
    const { userToken } = useApp()
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${userToken.value}`
      }
    }
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
}

/**
 * -------------------------------------
 *  Apollo Client Instances
 * -------------------------------------
 */
export const walletClient = createApolloClient(config.VUE_CONFIG_WALLET_API)
export const identityClient = createApolloClient(config.VUE_CONFIG_IDENTITY_API)
export const cashioClient = createApolloClient(config.VUE_CONFIG_CASHIO_API)
export const gameClient = createApolloClient(config.VUE_CONFIG_GAME_API)
export const loginClient = createApolloClient(config.VUE_CONFIG_IDENTITY_API)

/**
 * -------------------------------------
 *  Apollo Provider
 * -------------------------------------
 */
const apolloProvider = new VueApollo({
  clients: {
    walletClient,
    identityClient,
    cashioClient,
    gameClient,
    loginClient
  },
  defaultClient: cashioClient
})

Vue.filter('brl', value =>
  value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
)

Vue.filter('userDocument', (value, document) =>
  value.documents.filter(d => d.type === document).length > 0
    ? value.documents.filter(d => d.type == document)[0].value
    : 'Sem Info'
)

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
