import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        resource: 'Auth',
        action: 'read',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/GeneralReport.vue'),
      meta: {
        pageTitle: 'Relatório geral',
        action: 'manage',
        resource: 'reports',
        breadcrumb: [
          {
            text: 'Relatório geral',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients-list',
      name: 'clients-list',
      component: () => import('@/views/clients/ClientsList.vue'),
      meta: {
        pageTitle: 'Listagem de Clientes',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Listagem de Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-single/:clientId',
      name: 'client-single',
      component: () => import('@/views/clients/ClientSingle.vue'),
      meta: {
        pageTitle: 'Detalhe de Clientes',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Detalhe de Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-balance-tool/:clientId',
      name: 'client-balance-tool',
      component: () => import('@/views/clients/ClientBalanceTool.vue'),
      meta: {
        pageTitle: 'Correção de Balance',
        action: 'balance-tool',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Correção de Balance',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-rollover-tool/:clientId',
      name: 'client-rollover-tool',
      component: () => import('@/views/clients/ClientRolloverTool.vue'),
      meta: {
        pageTitle: 'Correccion de Rollover',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Correccion de Rollover',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-groups-list',
      name: 'user-groups-list',
      component: () => import('@/views/groups/UserGroupsList.vue'),
      meta: {
        pageTitle: 'Grupos de Clientes',
        action: 'read',
        resource: 'client-groups',
        breadcrumb: [
          {
            text: 'Grupos de Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-groups-create',
      name: 'user-groups-create',
      component: () => import('@/views/groups/UserGroupsCreate.vue'),
      meta: {
        pageTitle: 'Criar grupo de clientes',
        action: 'edit',
        resource: 'client-groups',
        breadcrumb: [
          {
            text: 'Criar grupo de clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        layout: 'full',
      },
    },
    {
      path: '/withdraws-list',
      name: 'withdraws-list',
      component: () => import('@/views/transactions/WithdrawsList.vue'),
      meta: {
        pageTitle: 'Saques',
        action: 'read',
        resource: 'withdrawals',
        breadcrumb: [
          {
            text: 'Saques',
            active: true,
          },
        ],
      },
    },
    {
      path: '/deposits-list',
      name: 'deposits-list',
      component: () => import('@/views/transactions/DepositsList.vue'),
      meta: {
        pageTitle: 'Depositos',
        action: 'read',
        resource: 'deposits',
        breadcrumb: [
          {
            text: 'Depositos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/consolidated',
      name: 'consolidated',
      component: () => import('@/views/transactions/Consolidated.vue'),
      meta: {
        pageTitle: 'Consolidado',
        action: 'manage',
        resource: 'financial-reports',
        breadcrumb: [
          {
            text: 'Consolidado',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ggr-consolidated',
      name: 'ggr-consolidated',
      component: () => import('@/views/transactions/GGRConsolidated.vue'),
      meta: {
        pageTitle: 'Consolidado GGR',
        action: 'manage',
        resource: 'financial-reports',
        breadcrumb: [
          {
            text: 'Consolidado GGR',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ftd',
      name: 'ftd',
      component: () => import('@/views/transactions/FTD.vue'),
      meta: {
        pageTitle: 'FTD',
        action: 'manage',
        resource: 'financial-reports',
        breadcrumb: [
          {
            text: 'FTD',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-promotions-list',
      name: 'user-promotions-list',
      component: () => import('@/views/promotions/UserPromotionsList.vue'),
      meta: {
        pageTitle: 'Promoções',
        action: 'read',
        resource: 'client-promotions',
        breadcrumb: [
          {
            text: 'Promoções',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotion/create',
      name: 'promotion-create',
      component: () => import('@/views/promotions/UserPromotionCreate.vue'),
      meta: {
        pageTitle: 'Criar promoção',
        action: 'edit',
        resource: 'client-promotions',
        breadcrumb: [
          {
            text: 'Criar promoção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotion-single/:promoId',
      name: 'promotion-single',
      component: () => import('@/views/promotions/UserPromotionSingle.vue'),
      meta: {
        pageTitle: 'Editar promoção',
        action: 'edit',
        resource: 'client-promotions',
        breadcrumb: [
          {
            text: 'Editar promoção',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotion-single-with-users/:promoId',
      name: 'promotion-single-with-users',
      component: () => import('@/views/promotions/UserPromotionSingleWithUser.vue'),
      meta: {
        pageTitle: 'Ver promoção con Usuarios',
        action: 'edit',
        resource: 'client-promotions',
        breadcrumb: [
          {
            text: 'Ver promoção',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users-playtime',
      name: 'users-playtime',
      component: () => import('@/views/promotions/UsersPlaytime.vue'),
      meta: {
        pageTitle: 'Brincadeiras',
        action: 'read',
        resource: 'client-promotions',
        breadcrumb: [
          {
            text: 'Brincadeiras',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/group-single/:groupId',
      name: 'group-single',
      component: () => import('@/views/groups/UserGroupSingle.vue'),
      meta: {
        pageTitle: 'Detalhe de grupo',
        action: 'edit',
        resource: 'client-groups',
        breadcrumb: [
          {
            text: 'Detalhe de grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/clients-list',
      name: 'master-clients-list',
      component: () => import('@/views/masters/clients/ClientsList.vue'),
      meta: {
        pageTitle: 'Listagem de clientes Master',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Listagem de clientes Master',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/client-single/:clientId',
      name: 'master-client-single',
      component: () => import('@/views/masters/clients/ClientSingle.vue'),
      meta: {
        pageTitle: 'Detalhe de cliente',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Detalhe de cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/masters/transactions',
      name: 'master-transactions',
      component: () => import('@/views/masters/transactions/MasterEnclosure.vue'),
      meta: {
        pageTitle: 'Detalhe de cliente',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Detalhe de cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/games-list',
      name: 'games-list',
      component: () => import('@/views/games/GamesList.vue'),
      meta: {
        pageTitle: 'Listagem de jogos',
        action: 'read',
        resource: 'games',
        breadcrumb: [
          {
            text: 'Listagem de jogos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/games-group-list',
      name: 'games-group-list',
      component: () => import('@/views/games/GamesGroupList.vue'),
      meta: {
        pageTitle: 'Listagem de grupos',
        action: 'read',
        resource: 'game-groups',
        breadcrumb: [
          {
            text: 'Listagem de grupos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/game-group-single/:id',
      name: 'game-group-single',
      component: () => import('@/views/games/GroupSingle.vue'),
      meta: {
        pageTitle: 'Edição de grupo',
        action: 'create',
        resource: 'game-groups',
        breadcrumb: [
          {
            text: 'Edição de grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master-clients-list',
      name: 'master-clients-list',
      component: () => import('@/views/masters/clients/ClientsList.vue'),
      meta: {
        pageTitle: 'Listagem de clientes Master',
        action: 'read',
        resource: 'client',
        breadcrumb: [
          {
            text: 'Listagem de clientes Master',
            active: true,
          },
        ],
      },
    },
    {
      path: '/master-transactions',
      name: 'master-transactions',
      component: () => import('@/views/masters/transactions/MasterEnclosure.vue'),
      meta: {
        pageTitle: 'Transações Master',
        action: 'read',
        resource: 'transactions',
        breadcrumb: [
          {
            text: 'Transações Master',
            active: true,
          },
        ],
      },
    },

    {
      path: '/rounds-list',
      name: 'rounds-list',
      component: () => import('@/views/roulette/rounds/RoundsList.vue'),
      meta: {
        pageTitle: 'Listagem de Rondas',
        action: 'be',
        resource: 'company-employee',
        breadcrumb: [
          {
            text: 'Listagem de Rondas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rooms-list',
      name: 'rooms-list',
      component: () => import('@/views/chat/RoomList.vue'),
      meta: {
        pageTitle: 'Listagem de Habitaciones de Chat',
        action: 'be',
        resource: 'company-employee',
        breadcrumb: [
          {
            text: 'Listagem de Rooms',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat-room/:roomId',
      name: 'chat-room',
      component: () => import('@/views/chat/Room.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
        action: 'read',
        resource: 'chat-room',

      },
    },
    {
      path: '/round-single/:roundId',
      name: 'round-single',
      component: () => import('@/views/roulette/rounds/RoundSingle.vue'),
      meta: {
        pageTitle: 'Detalhe de Ronda',
        action: 'read',
        resource: 'roulette-round',
        breadcrumb: [
          {
            text: 'Detalhe de Ronda',
            active: true,
          },
        ],
      },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const uData = JSON.parse(localStorage.getItem('userData'))
  if (uData && uData.expires?.length > 0) {
    const expires = new Date(uData.expires)
    const now = new Date()
    if (expires < now) {
      window.localStorage.removeItem('userData')
    }
  } else {
    window.localStorage.removeItem('userData')
  }
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      return next({ name: 'login' })
    }

    return next({ name: 'home' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
