import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://bananaprovider.com/admin',
  // timeout: 1000,
})

Vue.prototype.$gpas = axiosIns

export default axiosIns
