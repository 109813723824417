import useJwt from '@/auth/jwt/useJwt'
import router from '../router/index'

export default function useAuth() {
  const logout = () => {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    window.localStorage.removeItem('userToken')
    window.localStorage.removeItem('userData')
    router.push({ name: 'login' })
  }

  return {
    logout
  }
}
