import { ref } from '@vue/composition-api'
import useAuth from './use-auth'

const { logout } = useAuth()

const userToken = ref(window.localStorage.getItem('userToken') || null)

const tokenExpiredAt = ref(null)

const keyExpiredTimeOut = ref(null)

const keySetInterval = ref(null)

const onExpire = () => {
  window.localStorage.removeItem('userToken')
  userToken.value = null
  tokenExpiredAt.value = null
  logout()
}

export default function useApp() {
  const setUserToken = (token, expired) => {
    window.localStorage.setItem('userToken', token)
    userToken.value = token
    tokenExpiredAt.value = expired

    const futureTimestamp = expired

    const currentTimestamp = Math.floor(Date.now() / 1000)

    const secondsUntilExpiration = futureTimestamp - currentTimestamp

    const toMilliseconds = secondsUntilExpiration * 1000

    if (keyExpiredTimeOut.value) {
      clearTimeout(keyExpiredTimeOut.value)
    }

    keyExpiredTimeOut.value = setTimeout(() => {
      onExpire()
    }, toMilliseconds)

    if (keySetInterval.value) {
      clearInterval(keySetInterval)
    }

    let secondsUntilExpirationToLog = futureTimestamp - currentTimestamp

    keySetInterval.value = setInterval(() => {
      console.log('Seconds until expiration:', secondsUntilExpirationToLog)

      secondsUntilExpirationToLog -= 60

      if (secondsUntilExpirationToLog <= 0) {
        clearInterval(keySetInterval.value)
      }
    }, 60000)
  }

  return {
    userToken,
    setUserToken
  }
}
